<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="statistics-block">
            <el-row>
                <el-col :span="4">
                    <div class="grid-content bg-purple">
                        <p>账户余额:</p>
                        <p>￥{{wallet.money}}</p>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="grid-content bg-purple-light">
                        <p>未结佣金</p>
                        <p>￥{{statistics.withdrawal}}</p>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="grid-content bg-purple">
                        <p>已结佣金</p>
                        <p>￥{{statistics.withdrawal}}</p>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="grid-content bg-purple">
                        <p>剩余可提现</p>
                        <p>￥{{statistics.income}}</p>
                    </div>
                </el-col>

                <el-col :span="4">
                    <div class="grid-content bg-purple">
                        <p>已取消佣金</p>
                        <p>￥0</p>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="grid-content bg-purple">
                        <el-button type="primary">申请提现</el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="container">
            <div class="action">
                <el-tabs v-model="settlementMode" type="card" @tab-click="tabClick">
                    <el-tab-pane label="未结算佣金" name="unSettlement">未结算佣金</el-tab-pane>
                    <el-tab-pane label="已结算佣金" name="settlement">已结算佣金</el-tab-pane>
                    <el-tab-pane label="被取消佣金" name="settlementRefused">被取消佣金</el-tab-pane>
                </el-tabs>
            </div>
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="saleOrderList">
                <el-table-column label="id" prop="id" align="center" width="100"></el-table-column>
                <el-table-column label="订单日期" prop="ctime" align="center" width="180"></el-table-column>
                <el-table-column label="商品名称" prop="order_goods_list.title" align="center"></el-table-column>
                <el-table-column label="商品规格" prop="order_goods_list.attr" width="180" >
                </el-table-column>
                <el-table-column label="订单号" prop="ordersn" align="center"></el-table-column>
                <el-table-column label="结算金额" prop="price" align="center"></el-table-column>
                <el-table-column label="结算佣金" prop="commission_price" align="center"></el-table-column>
                <el-table-column label="状态" prop="is_settlement" align="center" width="100">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.is_settlement === 1">未结算</el-tag>
                        <el-tag type="primary" v-if="scope.row.is_settlement === 2">已经结算</el-tag>
                        <el-tag type="primary" v-if="scope.row.is_settlement === 11">取消结算</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>

    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import {request} from '@/common/request';
import {deal} from '@/common/main';
import Picture from '@/components/Picture';
import {IMAGE_UPLOAD_PRO_URL} from "@/common/const";
import {getUserInfo} from "@/utils/user";

export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            isSync: true,
            defaultImg: require('../../assets/img/zhan.jpeg'),
            header: {},
            confirmVisible: false,
            confirmContent: '',
            menuList: ['资金', '我的账户'],
            loading: false,
            queryParams: {
                page: 1,
                pagesize: 10,
                platform: 'admin',
            },
            settlementMode:"unSettlement",  //选中的结算标签页面
            pageInfo: {},
            addVisible: false,
            title: '添加银行卡',
            form: {
                state: 1,
                name: '',
                time: null
            },

            saleOrderList:[],
            wallet:{},
            statistics:{}
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData()
        this.loadStatisticsData()
    },
    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;
        },
        choiceImg() {
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, 'logo', url);
        },
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        // 图片上传
        handleAvatarSuccess(res, file) {
            if (res.code === 1) {
                this.$set(this.form, 'logo', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            const _this = this
            return deal.imageFormat(file, _this)
        },
        cellstyle() {
            return "text-align: center";
        },
        //银行卡列表
        loadData() {
            // this.loading = true;
            this.queryParams.settlement_mode=this.settlementMode
            request.get('/order/partner/sale/list', this.queryParams).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.saleOrderList = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //用户统计信息
        loadStatisticsData() {
            let userInfo = getUserInfo()
            // this.loading = true;
            request.get('/partner/account/statistics', {uid: userInfo.uid}).then(ret => {
                if (ret.code === 1) {
                    this.wallet = ret.data.wallet
                    this.statistics = ret.data.statistics
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //新增编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode === 'add' ? '/wallet/bank/add' : '/wallet/bank/edit';
                        //处理时间
                        request.post(url, this.form).then(ret => {
                            if (ret.code === 1) {
                                this.addVisible = false;
                                this.$message.success((this.mode === 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.loadData();
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },

        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        // 编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑银行卡';
            this.current = row.id;
            const item = this.pageInfo.list[index];
            let time = [item.ctime, item.etime]
            //处理时间
            this.form = Object.assign({}, item);
            this.$set(this.form, 'ctime', time);
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        // 新增
        add() {
            this.mode = 'add';
            this.title = '新增银行卡';
            this.form = {
                state: 2
            };
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        state(row, state) {
            if (state === 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },
        // 确定下线
        stateData() {
            request.post('/wallet/bank/edit', this.form).then(ret => {
                if (ret.code === 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 同步
        sync() {
            this.isSync = false
            request.get('/wallet/bank/sync').then(ret => {
                if (ret.code === 1) {
                    this.isSync = true
                    this.$message.success('同步成功');
                    this.loadData();
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        tabClick(){
            this.loadData()
        }
    }
}
</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 126px;
    height: 36px;
    line-height: 36px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 126px;
    height: 36px;
    line-height: 36px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
.dialog_box .el-button {
    margin-left: 20px;
}
.statistics-block{
    height: 100px;
    background-color: #fff;
    margin-bottom: 20px;
    padding: 50px;
}
</style>
